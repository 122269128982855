/* This Component reused for loading and No rows found cases.
 */
import React from "react";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import PropTypes from "prop-types";
import { xlate } from "../../i18n/locales";

function CustomLoadingOverlay(props) {
  let content = null;
  // ? case1: Loading...
  if (props.isLoading) {
    content = (
      <div className="">
        {/* <span> {props.loadingMessage}</span> */}
        <LoadingIndicator htmlId="maskSpinner" size="large" color="gray" />
      </div>
    );
  }
  // ? case2: No records found
  if (props.noRows) {
    content = (
      <div className="xmm-loader-msg">
        <i className="fa fa-frown-o" />
        <span> {xlate("xmm.portal.common.no_records_msg")}</span>
      </div>
    );
  }
  return content;
}

export default CustomLoadingOverlay;

CustomLoadingOverlay.propTypes = {
  loadingMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  noRows: PropTypes.bool
};
